import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { createGameFromOptions } from "api";
import {
  GameBoards,
  ValidTurnLimits,
  ValidNumberOfPlayers,
  ValidPointTotals,
} from "gameplay/types";
import { Button, Modal, Dropdown } from "ui";
import { Route } from "routes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setGameBoardSelection,
  setNumberOfPlayersSelection,
  setPointTotalSelection,
  setTurnLimitSelection,
} from "store/gameOptions";

export function CreateGameButton() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gameOptions = useAppSelector((state) => state.gameOptions);

  const [modalOpen, setModalOpen] = useState(false);

  const handleStartGame = () => {
    createGameFromOptions(gameOptions).then((gameId) => {
      navigate(`${Route.MULTIPLAYER_LOBBY}${gameId}/`);
    });
  };

  return (
    <div className="createGameButton">
      <div className="buttonContainer">
        <Button variant="filled" onClick={() => setModalOpen((prev) => !prev)}>
          Create Game
        </Button>
      </div>
      <Modal
        name="createGameModal"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Create Game"
      >
        <Dropdown
          label="Number of Players"
          options={Array.from(ValidNumberOfPlayers)}
          onChange={(value) => dispatch(setNumberOfPlayersSelection(value))}
          value={gameOptions.numberOfPlayers}
        />
        <Dropdown
          label="Point Total"
          options={Array.from(ValidPointTotals)}
          onChange={(value) => dispatch(setPointTotalSelection(value))}
          value={gameOptions.pointTotal}
        />
        <Dropdown
          label="Game Board"
          options={Array.from(GameBoards)}
          onChange={(value) => dispatch(setGameBoardSelection(value))}
          value={gameOptions.gameBoard}
        />
        <Dropdown
          label="Turn Limit"
          options={Array.from(ValidTurnLimits)}
          onChange={(value) => dispatch(setTurnLimitSelection(value))}
          value={gameOptions.turnLimit}
        />
        <Button
          fullWidth
          variant="filled"
          color="primary"
          onClick={handleStartGame}
        >
          Start
        </Button>
      </Modal>
    </div>
  );
}
