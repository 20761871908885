import { useCurrentPlayerFaction } from "gameplay/hooks";
import { Box, Text } from "ui";

import { PointHeader } from "./PointHeader";
import { Sidebar } from "./Sidebar";
import { UnitModificationMenu } from "./UnitModificationMenu";
import "./styles.scss";

/* Check if the store has values needed to let a user compose their army */
function useReadyForComposition() {
  try {
    useCurrentPlayerFaction();
    return true;
  } catch (err) {
    return false;
  }
}

export function ArmyCompositionScene() {
  const readyForComposition = useReadyForComposition();
  if (!readyForComposition) {
    return (
      <Box>
        <Text variant="h3">Loading...</Text>"
      </Box>
    );
  }

  return (
    <Box id="armyCompositionScene">
      <Box className="container">
        <Box
          className="sceneContainer"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" className="header">
            <PointHeader />
          </Box>
          <UnitModificationMenu />
        </Box>
        <Box
          className="sidebarContainer"
          display="flex"
          justifyContent="space-between"
        >
          <Sidebar />
        </Box>
      </Box>
    </Box>
  );
}
