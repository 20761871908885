import "./styles.scss";

import { MouseEventHandler, ReactNode, useMemo } from "react";

type ButtonVariant = "contained" | "filled" | "text";
type ColorVariant = "primary";

interface IButton {
  className?: string;
  children: ReactNode;
  color?: ColorVariant;
  disabled?: boolean;
  fullWidth?: boolean;
  active?: boolean;
  onClick?: MouseEventHandler;
  size?: "small" | "medium" | "large";
  variant: ButtonVariant;
}
export function Button({
  active,
  color,
  disabled,
  fullWidth,
  className,
  children,
  onClick,
  size = "medium",
  variant = "contained",
}: IButton) {
  const buttonClassName = useMemo(() => {
    const classes = ["uiButton", variant];
    if (color) {
      classes.push(`color-${color}`);
    }
    if (disabled) {
      classes.push("disabled");
    }
    if (className) {
      classes.push(className);
    }
    if (active) {
      classes.push("active");
    }
    if (fullWidth) {
      classes.push("fullWidth");
    }
    if (size) {
      classes.push(`size-${size}`);
    }
    return classes.join(" ");
  }, [active, className, color, disabled, fullWidth, size, variant]);

  const handleClick = onClick && !disabled ? onClick : undefined;

  return (
    <button className={buttonClassName} onClick={handleClick}>
      {children}
    </button>
  );
}
