import { Screen } from "components";
import { Route } from "routes";
import { ArmyCompositionScene } from "scenes/composition";

export function MultiplayerCompose() {
  return (
    <Screen
      id="multiplayerCompose"
      route={Route.MULTIPLAYER_COMPOSE}
      background="black"
    >
      <ArmyCompositionScene />
    </Screen>
  );
}
