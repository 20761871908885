import * as THREE from "three";

import { createContext, useContext } from "react";
import { Object3D } from "three";

interface Hierarchy {
  parent?: THREE.Object3D;
}
export const HierarchyContext = createContext<Hierarchy>({
  parent: undefined,
});

export function useParent(): Object3D | undefined {
  return useContext(HierarchyContext).parent;
}
