import { useMemo } from "react";

import { FactionName, GamePlayer } from "gameplay/types";
import { useAppSelector } from "store/hooks";
import { SAMPLE_USER_ID } from "./constants";

export function useCurrentPlayer(): GamePlayer {
  const userId = SAMPLE_USER_ID;
  const players = useAppSelector((s) => s.gameRound.players);
  const currentPlayer = players.find((p) => p.entrant.id === userId);
  if (!currentPlayer) {
    throw new Error("Player not found");
  }
  return currentPlayer;
}

export function useCurrentPlayerFaction(): FactionName[] {
  const player = useCurrentPlayer();
  return player.factions;
}

export function useCurrentArmyValue() {
  const units = useAppSelector((state) => state.composition.units);
  const { factionModifications } = useAppSelector((state) => state.composition);

  const value = useMemo(() => {
    let total = 0;
    units.forEach((unit) => {
      total += unit.cost;
      unit.modifications.forEach((mod) => {
        total += mod.cost;
      });
    });
    factionModifications.forEach((mod) => {
      total += mod.cost;
    });
    return total;
  }, [factionModifications, units]);

  return value;
}

export function usePointsRemaining() {
  const armyValue = useCurrentArmyValue();
  const pointTotal = useAppSelector((state) => state.gameOptions.pointTotal);
  return pointTotal - armyValue;
}

export function useGameRound() {
  return useAppSelector((state) => state.gameRound);
}
