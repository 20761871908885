import { startGame } from "api/actions";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "store/hooks";
import { Box, Button } from "ui";
import { Route } from "routes";

export function StartButton() {
  const navigate = useNavigate();
  const gameRound = useAppSelector((state) => state.gameRound);

  const [label, disabled] = useMemo<[string, boolean]>(() => {
    const { status } = gameRound;
    if (status === "Not Instantiated") {
      return ["Loading...", true];
    } else if (status === "Ready to Start") {
      return ["Start", false];
    } else if (
      status === "Active" ||
      status === "Completed" ||
      status === "Awaiting Army Composition"
    ) {
      return ["Game Already Started", true];
    } else {
      return [status, true];
    }
  }, [gameRound]);

  const handleStart = () => {
    startGame(gameRound.id).then(() => {
      navigate(`${Route.MULTIPLAYER_COMPOSE}${gameRound.id}`);
    });
  };

  return (
    <Box py={4} display="flex" justifyContent="center">
      <Button variant="filled" disabled={disabled} onClick={handleStart}>
        {label}
      </Button>
    </Box>
  );
}
