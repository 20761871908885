import { Modification } from "./modifications";
import { FactionName } from "./types";

export interface Unit {
  faction: FactionName;
  health: number;
  id: string;
  maxHealth: number;
  modifications: Modification[];
  nickname: string;
  cost: number;
}

export interface BigGuyUnit extends Unit {
  faction: "Big Guy";
  numberOfLegs: number;
}

export const BaseUnits: Record<FactionName, Unit> = {
  Tolja: {
    faction: "Tolja",
    health: 100,
    id: crypto.randomUUID(),
    maxHealth: 100,
    modifications: [],
    cost: 100,
    nickname: "Tolja Unit",
  },
  "Red Queens": {
    faction: "Red Queens",
    health: 200,
    id: crypto.randomUUID(),
    maxHealth: 200,
    modifications: [],
    cost: 200,
    nickname: "Red Queen Soldier",
  },
  Consensus: {
    faction: "Consensus",
    health: 1,
    id: crypto.randomUUID(),
    maxHealth: 1,
    modifications: [],
    cost: 1,
    nickname: "Consensus Member",
  },
  "Big Guy": {
    faction: "Big Guy",
    health: 100,
    id: crypto.randomUUID(),
    maxHealth: 100,
    modifications: [],
    cost: 150,
    nickname: "The Big Guy",
  },
  Squishies: {
    faction: "Squishies",
    health: 75,
    id: crypto.randomUUID(),
    maxHealth: 75,
    modifications: [],
    cost: 100,
    nickname: "Squishy Scholar",
  },
  Iridescent: {
    faction: "Iridescent",
    health: 175,
    id: crypto.randomUUID(),
    maxHealth: 175,
    modifications: [],
    cost: 200,
    nickname: "Iridescent Guardian",
  },
  Wise: {
    faction: "Wise",
    health: 150,
    id: crypto.randomUUID(),
    maxHealth: 150,
    modifications: [],
    cost: 250,
    nickname: "Wise",
  },
};

export function getBaseUnitForFaction(faction: FactionName) {
  return {
    ...BaseUnits[faction],
    id: crypto.randomUUID(),
  };
}
