import { useAppSelector } from "store/hooks";
import { Box, Text } from "ui";

export function GameDetails() {
  const gameOptions = useAppSelector((state) => state.gameOptions);
  return (
    <Box>
      <Text variant="h2">Game Details</Text>
      <Box pl={4} py={2}>
        <Text variant="body">Board: {gameOptions.gameBoard}</Text>
        <Text variant="body">Player Count: {gameOptions.numberOfPlayers}</Text>
        <Text variant="body">Point Total: {gameOptions.pointTotal}</Text>
        <Text variant="body">Turn Limit: {gameOptions.turnLimit}</Text>
      </Box>
    </Box>
  );
}
