import { ReactNode, useEffect, useMemo } from "react";
import * as analytics from "analytics";
import { Route } from "routes";
import { MenuBackgroundImage } from "./MenuBackgroundImage";

import "./styles.scss";

interface IScreen {
  background?: "nyc" | "black";
  children: ReactNode;
  id: string;
  route: Route;
}

export function Screen({ background = "black", route, id, children }: IScreen) {
  useEffect(() => {
    analytics.pageLoad(route);
  }, [route]);

  const className = useMemo(() => {
    const classes = ["uiScreen"];
    if (background) {
      classes.push(`bg-${background}`);
    }
    return classes.join(" ");
  }, [background]);

  return (
    <div id={id} className={className}>
      {background === "nyc" && <MenuBackgroundImage />}
      {children}
    </div>
  );
}
