import { Movable, useMovement } from "gamehook/physics/movement";
import { Positional, usePosition } from "gamehook/physics/position";
import { useThreeScene } from "gamehook/scene/context";
import { ReactNode, useEffect, useState } from "react";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const loader = new GLTFLoader();

interface IModel extends Movable, Positional {
  children?: ReactNode;
  path: string;
  id?: string;
}

export function Model({ children, path, ...props }: IModel) {
  const scene = useThreeScene();
  const [loadedModel, setLoadedModel] = useState<GLTF | undefined>(undefined);

  useEffect(() => {
    loader.load(
      path,
      (gltf: GLTF) => {
        setLoadedModel(gltf);
      },
      undefined,
      function (error) {
        console.log(error);
      }
    );
  }, [path]);

  useEffect(() => {
    if (loadedModel) {
      scene.add(loadedModel.scene);
    }
    return () => {
      if (loadedModel) {
        scene.remove(loadedModel.scene);
      }
    };
  }, [loadedModel, scene]);

  useMovement(loadedModel?.scene, props);
  usePosition(loadedModel?.scene, props);

  return <>{children}</>;
}
