import { ReactNode, useMemo } from "react";
import { Button, Text } from "ui";

import "./styles.scss";

interface IModal {
  children: ReactNode;
  name: string;
  open: boolean;
  onClose: () => void;
  title: string;
}

export function Modal({ open, onClose, children, name, title }: IModal) {
  const wrapperClass = useMemo(() => {
    return ["uiModal", "wrapper", open ? "open" : "closed"].join(" ");
  }, [open]);

  return (
    <div className={wrapperClass}>
      <div className="container">
        <div className="header">
          <Text variant="h1">{title}</Text>
          <Button variant="filled" color="primary" onClick={onClose}>
            Close
          </Button>
        </div>
        {children}
      </div>
    </div>
  );
}
