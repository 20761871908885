import * as THREE from "three";
import { useCallback, useEffect } from "react";

import { useCamera } from "gamehook/camera/hooks";
import { XYZ } from "gamehook/physics/types";

interface IControl {
  movementSpeed?: number;
  rotationSpeed?: number;
  listenerType?: "keypress" | "keyup" | "keydown";
  variant: "fly";
}
export function Control({
  listenerType = "keydown",
  movementSpeed = 1,
  rotationSpeed = 0.05,
  variant,
}: IControl) {
  const camera = useCamera();

  if (variant !== "fly") {
    throw new Error(`TODO: Control ${variant} not yet supported`);
  }

  const listenForInput = useCallback(
    (event: KeyboardEvent) => {
      console.log(event);
      const { key } = event;
      if (key === "w") {
        moveCamera(camera, [0, 0, 0 - movementSpeed]);
      } else if (key === "s") {
        moveCamera(camera, [0, 0, movementSpeed]);
      } else if (key === "a") {
        moveCamera(camera, [0 - movementSpeed, 0, 0]);
      } else if (key === "d") {
        moveCamera(camera, [movementSpeed, 0, 0]);
      } else if (key === "r") {
        moveCamera(camera, [0, movementSpeed, 0]);
      } else if (key === "f") {
        moveCamera(camera, [0, 0 - movementSpeed, 0]);
      } else if (key === "q") {
        rotateCamera(camera, [0, rotationSpeed, 0]);
      } else if (key === "e") {
        rotateCamera(camera, [0, -rotationSpeed, 0]);
      }
    },
    [camera, movementSpeed, rotationSpeed]
  );

  useEffect(() => {
    window.addEventListener(listenerType, listenForInput);
    return () => {
      window.removeEventListener(listenerType, listenForInput);
    };
  }, [listenerType, listenForInput]);

  return <></>;
}

const DefaultDuration = 125;
function moveCamera(
  camera: THREE.Camera,
  movement: XYZ,
  duration = DefaultDuration,
  smooth = true
) {
  if (!smooth) {
    camera.translateX(movement[0]);
    camera.translateY(movement[1]);
    camera.translateZ(movement[2]);
    return;
  }

  const start = Date.now();
  let current = Date.now();

  function animate() {
    const tick = Date.now();
    const elapsed = tick - start;
    const progress = elapsed / duration;
    const delta = (tick - current) / duration;
    current = tick;

    if (progress >= 1) {
      return;
    } else {
      requestAnimationFrame(animate);
      camera.translateX(movement[0] * delta);
      camera.translateY(movement[1] * delta);
      camera.translateZ(movement[2] * delta);
    }
  }
  animate();
}

function rotateCamera(
  camera: THREE.Camera,
  movement: XYZ,
  duration = DefaultDuration,
  smooth = true
) {
  if (!smooth) {
    camera.rotateX(movement[0]);
    camera.rotateY(movement[1]);
    camera.rotateZ(movement[2]);
    return;
  }

  const start = Date.now();
  let current = Date.now();

  function animate() {
    const tick = Date.now();
    const elapsed = tick - start;
    const progress = elapsed / duration;
    const delta = (tick - current) / duration;
    current = tick;

    if (progress >= 1) {
      return;
    } else {
      requestAnimationFrame(animate);
      camera.rotation.x += movement[0] * delta;
      camera.rotation.y += movement[1] * delta;
      camera.rotation.z += movement[2] * delta;
    }
  }
  animate();
}
