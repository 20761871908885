import { ReactNode, useMemo } from "react";

import "./styles.scss";

type TextVariant = "subtitle" | "title" | "h1" | "h2" | "h3" | "h4" | "body";

interface IText {
  children: ReactNode;
  color?: "dark" | "light";
  textAlign?: "center";
  variant?: TextVariant;
}

export function Text({
  children,
  color = "light",
  textAlign,
  variant = "h1",
}: IText) {
  const className = useMemo(() => {
    const classes = ["uiText", variant, color];
    if (textAlign) {
      classes.push(`textAlign-${textAlign}`);
    }
    return classes.join(" ");
  }, [color, textAlign, variant]);

  switch (variant) {
    case "title":
      return <h1 className={className}>{children}</h1>;
    case "subtitle":
      return <span className={className}>{children}</span>;
    case "h1":
      return <h1 className={className}>{children}</h1>;
    case "h2":
      return <h2 className={className}>{children}</h2>;
    case "h3":
      return <h3 className={className}>{children}</h3>;
    case "h4":
      return <h4 className={className}>{children}</h4>;
    case "body":
      return <p className={className}>{children}</p>;
    default:
      return <span>{children}</span>;
  }
}
