import { useCurrentPlayerFaction, useCurrentArmyValue } from "gameplay/hooks";
import { BaseUnits, getBaseUnitForFaction } from "gameplay/units";
import { useCallback, useEffect, useMemo } from "react";
import { addLegToBigGuy, addUnitToArmy, clearUnits } from "store/composition";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Button } from "ui";

export function AddUnitButton() {
  const dispatch = useAppDispatch();

  const faction = useCurrentPlayerFaction()[0];
  const baseUnit = BaseUnits[faction];

  // Compute if the player has sufficient points remaining to add another unit
  const currentArmyValue = useCurrentArmyValue();
  const pointTotal = useAppSelector((state) => state.gameOptions.pointTotal);
  const pointsRemaining = pointTotal - currentArmyValue;

  // Add initial units on page load
  useInitialUnits();

  // When clicked, check the faction and add the appropriate unit to the army
  const addUnit = useCallback(() => {
    if (faction === "Big Guy") {
      dispatch(addLegToBigGuy());
    } else if (faction === "Consensus") {
      throw new Error("TODO: Not implemented");
    } else {
      const unit = { ...BaseUnits[faction], id: crypto.randomUUID() };
      dispatch(addUnitToArmy(unit));
    }
  }, [dispatch, faction]);

  // Disable the button if the player can't add new units
  const addButtonDisabled = useMemo(() => {
    return pointsRemaining < baseUnit.cost;
  }, [pointsRemaining, baseUnit.cost]);

  if (faction === "Big Guy") {
    return (
      <Button
        variant="filled"
        onClick={addUnit}
        disabled={addButtonDisabled}
        fullWidth
      >
        Add Leg
        <br />({baseUnit.cost}pts)
      </Button>
    );
  } else if (faction === "Consensus") {
    return (
      <Button
        variant="filled"
        onClick={addUnit}
        disabled={addButtonDisabled}
        fullWidth
      >
        Add Unit
      </Button>
    );
  } else {
    return (
      <Button
        variant="filled"
        onClick={addUnit}
        disabled={addButtonDisabled}
        fullWidth
      >
        Add Unit
        <br />({baseUnit.cost}pts)
      </Button>
    );
  }
}

/* Add initial units into army */
function useInitialUnits() {
  const dispatch = useAppDispatch();
  const faction = useCurrentPlayerFaction()[0];

  useEffect(() => {
    dispatch(clearUnits());
    const baseUnit = getBaseUnitForFaction(faction);
    dispatch(addUnitToArmy(baseUnit));
  }, [dispatch, faction]);
}
