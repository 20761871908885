import { FactionName } from "./types";

export interface Modification {
  cost: number;
  name: string;
  description: string;
}

// Mods that apply to the entire army
export interface FactionModification extends Modification {
  isFactionModification: true;
}

export const UnitModifications: Record<FactionName, Modification[]> = {
  Tolja: [
    {
      cost: 100,
      name: "Motorcycle",
      description:
        "2-wheeled earth vehicle. Makes the unit significantly faster",
    },
    {
      cost: 125,
      name: "Plasma Rifle",
      description: "Ranged weapon",
    },
    {
      cost: 75,
      name: "Body Armor",
      description: "Increases unit HP by 50",
    },
  ],
  "Red Queens": [
    {
      cost: -50,
      name: "Scent of Weakness",
      description:
        "Unit must attack any unit with less than 50% health when possible",
    },
    {
      cost: -100,
      name: "Pack Mentality",
      description:
        "If unit isn't within 50 meters of a friendly unit, the unit will spend its movement getting closer to the nearest friendly unit",
    },
    {
      cost: 150,
      name: "Explosive Leap",
      description:
        "Unit may attack airborne units as though they were in melee range",
    },
  ],
  "Big Guy": [],
  Consensus: [],
  Squishies: [],
  Iridescent: [],
  Wise: [],
};
