export const GameBoards = [
  "Random",
  "HQ",
  "Antarctica",
  "Big Guy Domain",
] as const;
export type GameBoardOption = typeof GameBoards[number];

export const ValidPointTotals = [
  1000, 1500, 2000, 2500, 3000, 3500, 4000,
] as const;
export type PointTotalOption = typeof ValidPointTotals[number];

export const ValidNumberOfPlayers = [2, 3, 4, 5, 6, 7, 8] as const;
export type NumberOfPlayersOption = typeof ValidNumberOfPlayers[number];

export const ValidTurnLimits = ["No Limit", 25, 50, 100, 200] as const;
export type TurnLimitOption = typeof ValidTurnLimits[number];

export interface GameOptions {
  numberOfPlayers: NumberOfPlayersOption;
  pointTotal: PointTotalOption;
  gameBoard: GameBoardOption;
  turnLimit: TurnLimitOption;
}

export type GameStatus =
  | "Not Instantiated"
  | "Awaiting Players"
  | "Awaiting Faction Selection"
  | "Ready to Start"
  | "Awaiting Army Composition"
  | "Active"
  | "Completed"
  | "Cancelled";

export interface GameRound {
  id: string;
  status: GameStatus;
  players: GamePlayer[];
}

/* Players */
interface BasePlayer {
  username: string;
  isComputer: true | false;
  id: string;
}
interface HumanPlayer extends BasePlayer {
  isComputer: false;
  isHost: boolean;
  isLocal: boolean;
}

type ComputerDifficultyOption = "Easy" | "Medium" | "Hard";
export interface ComputerPlayer extends BasePlayer {
  difficulty: ComputerDifficultyOption;
  isHost: false;
  isLocal: false;
  isComputer: true;
}

export type Player = ComputerPlayer | HumanPlayer;
export interface GamePlayer {
  entrant: Player;
  factions: FactionName[];
}

// Factions
export const FactionNames = [
  "Tolja",
  "Red Queens",
  "Consensus",
  "Big Guy",
  "Squishies",
  "Iridescent",
  "Wise",
] as const;
export type FactionName = typeof FactionNames[number];
