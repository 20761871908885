import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FactionName, GamePlayer, GameStatus } from "gameplay/types";

interface GameRoundState {
  id: string;
  players: GamePlayer[];
  status: GameStatus;
}
const initialState: GameRoundState = {
  id: "sampleGameRound",
  players: [],
  status: "Not Instantiated",
};

export const gameRoundSlice = createSlice({
  name: "gameRound",
  initialState,
  reducers: {
    addPlayerToGame: (state, action: PayloadAction<GamePlayer>) => {
      const playerIds = state.players.map((p) => p.entrant.id);
      const playerInGame = playerIds.includes(action.payload.entrant.id);
      if (!playerInGame) {
        state.players.push(action.payload);
      }
    },
    removePlayerFromGame: (state, action: PayloadAction<string>) => {
      state.players = state.players.filter(
        (p) => p.entrant.id !== action.payload
      );
    },
    setFactionSelectionForPlayer(
      state,
      action: PayloadAction<{ entrantId: string; factions: FactionName[] }>
    ) {
      const { entrantId, factions } = action.payload;
      state.players.forEach((player) => {
        if (player.entrant.id === entrantId) {
          player.factions = factions;
        }
      });
    },
    setGameRoundStatus(state, action: PayloadAction<GameStatus>) {
      state.status = action.payload;
    },
    setGameRound(_state, action: PayloadAction<GameRoundState>) {
      return action.payload;
    },
  },
});

export const {
  addPlayerToGame,
  removePlayerFromGame,
  setFactionSelectionForPlayer,
  setGameRoundStatus,
  setGameRound,
} = gameRoundSlice.actions;

export default gameRoundSlice.reducer;
