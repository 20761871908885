import { GameOptions, GameRound } from "gameplay/types";
import { SAMPLE_USER_ID } from "gameplay/constants";

export async function createGameFromOptions(
  _gameOptions: GameOptions
): Promise<string> {
  // Eventually will hit the API
  return crypto.randomUUID();
}

const TODO_SampleGameOptions: GameOptions = {
  gameBoard: "Random",
  turnLimit: "No Limit",
  numberOfPlayers: 2,
  pointTotal: 2000,
};
export async function fetchGameOptions(_gameId: string): Promise<GameOptions> {
  return TODO_SampleGameOptions;
}

export async function fetchGameRound(_gameId: string): Promise<GameRound> {
  // TODO
  return {
    id: "sampleGameRound",
    status: "Not Instantiated",
    players: [
      {
        entrant: {
          id: SAMPLE_USER_ID,
          isComputer: false,
          isHost: true,
          isLocal: true,
          username: "You",
        },
        factions: ["Tolja"],
      },
    ],
  };
}

export async function startGame(_gameId: string) {
  return true;
}
