import { createBrowserRouter } from "react-router-dom";

import { Route } from "./routes";
import { WelcomeScreen } from "./screens/welcome";
import { MainMenuScreen } from "./screens/mainmenu";
import { CampaignMenuScreen } from "./screens/campaign/menu";

import { MultiplayerCompose } from "./screens/multiplayer/compose";
import { MultiplayerMenuScreen } from "./screens/multiplayer/menu";
import { MultiplayerLobby } from "screens/multiplayer/lobby";
import { MultiplayerRound } from "screens/multiplayer/round";

const router = createBrowserRouter([
  {
    path: Route.WELCOME,
    element: <WelcomeScreen />,
  },
  {
    path: Route.MAIN_MENU,
    element: <MainMenuScreen />,
  },
  {
    path: Route.CAMPAIGN_MENU,
    element: <CampaignMenuScreen />,
  },
  {
    path: Route.MULTIPLAYER_MENU,
    element: <MultiplayerMenuScreen />,
  },
  {
    path: `${Route.MULTIPLAYER_LOBBY}:gameId`,
    element: <MultiplayerLobby />,
  },
  {
    path: `${Route.MULTIPLAYER_COMPOSE}:gameId`,
    element: <MultiplayerCompose />,
  },
  {
    path: `${Route.MULTIPLAYER_ROUND}:gameId`,
    element: <MultiplayerRound />,
  },
]);

export default router;
