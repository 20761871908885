import { Box } from "ui";

import { AddUnitButton } from "./AddUnitButton";
import { UnitList } from "./UnitList";
import { StartGameButton } from "./StartGameButton";

export function Sidebar() {
  return (
    <Box
      className="sidebar"
      p={3}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <AddUnitButton />
      <UnitList />
      <StartGameButton />
    </Box>
  );
}
