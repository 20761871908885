import { Route } from "routes";
import { GameOptions } from "gameplay/types";

export function pageLoad(route: Route) {
  console.log("Loaded a page", { route });
}

type AnalyticsEvent = {
  event: "Game Started";
  gameOptions: GameOptions;
};
export function event(event: AnalyticsEvent) {
  console.log("Event", event);
}
