import { Route } from "routes";
import { Screen } from "components/Screen";
import { useMemo } from "react";

import { Button } from "ui";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

interface MainMenuOption {
  label: string;
  onClick: () => void;
}

const useMainMenuOptions = (): MainMenuOption[] => {
  const navigate = useNavigate();

  return useMemo(() => {
    return [
      {
        label: "CAMPAIGN",
        onClick: () => navigate(Route.CAMPAIGN_MENU),
      },
      {
        label: "MULTIPLAYER",
        onClick: () => navigate(Route.MULTIPLAYER_MENU),
      },
      {
        label: "CHALLENGE",
        onClick: () => navigate(Route.CHALLENGE),
      },
      {
        label: "SETTINGS",
        onClick: () => navigate(Route.SETTINGS),
      },
      {
        label: "← Back",
        onClick: () => navigate(Route.WELCOME),
      },
    ];
  }, [navigate]);
};

export function MainMenuScreen() {
  const options = useMainMenuOptions();

  return (
    <Screen route={Route.MAIN_MENU} id="mainMenu" background="nyc">
      <div className="optionsContainer">
        {options.map((option) => {
          return (
            <Button
              variant="filled"
              onClick={option.onClick}
              key={option.label}
            >
              {option.label}
            </Button>
          );
        })}
      </div>
    </Screen>
  );
}
