import { useNavigate } from "react-router-dom";
import { Screen } from "components/Screen";

import { Button, Container, PageCenter } from "ui";
import { Route } from "routes";

import "./styles.scss";
import { JoinGameMenu } from "./joinGameMenu";
import { CreateGameButton } from "./createGameButton";

export function MultiplayerMenuScreen() {
  const navigate = useNavigate();

  return (
    <Screen
      route={Route.MULTIPLAYER_MENU}
      id="multiplayerMenuScreen"
      background="nyc"
    >
      <PageCenter>
        <Container fixed>
          <div className="gameSelectonContainer">
            <CreateGameButton />
            <JoinGameMenu />
            <Button onClick={() => navigate(Route.MAIN_MENU)} variant="filled">
              ← Back
            </Button>
          </div>
        </Container>
      </PageCenter>
    </Screen>
  );
}
