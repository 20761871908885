import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import compositionReducer from "./composition";
import gameRoundReducer from "./gameRound";
import gameOptionsReducer from "./gameOptions";
import userReducer from "./user";

const reducer = {
  composition: persistReducer(
    { key: "persistedComposition", storage },
    compositionReducer
  ),
  gameOptions: persistReducer(
    { key: "persistedGameOptions", storage },
    gameOptionsReducer
  ),
  gameRound: persistReducer(
    { key: "persistedGameRound", storage },
    gameRoundReducer
  ),
  user: persistReducer(
    {
      key: "persistedUser",
      storage,
    },
    userReducer
  ),
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Used to get typing for rootstate, workaround with redux-persist middleware
const storeWithoutMiddleware = configureStore({
  reducer,
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof storeWithoutMiddleware.getState>;
export type AppDispatch = typeof store.dispatch;
