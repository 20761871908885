import { CoverImage } from "ui";

export function MenuBackgroundImage() {
  return (
    <CoverImage
      alt="Watercolor portrait of the NYC skyline"
      src="/assets/NYCWatercolorV2.png"
    />
  );
}
