import { Text } from "ui";

export function JoinGameMenu() {
  return (
    <div className="gameMenu joinGameMenu">
      <Text variant="h3" color="light">
        Join Game
      </Text>
    </div>
  );
}
