import {
  useCurrentArmyValue,
  useCurrentPlayerFaction,
  usePointsRemaining,
} from "gameplay/hooks";
import { Modification, UnitModifications } from "gameplay/modifications";
import { Unit } from "gameplay/units";
import { useCallback, useMemo } from "react";
import {
  addModificationToActiveUnit,
  removeModificationFromActiveUnit,
  removeUnitFromArmy,
} from "store/composition";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Box, Button, Text } from "ui";

export function UnitModificationMenu() {
  const dispatch = useAppDispatch();
  const id = useAppSelector((state) => state.composition.activeUnitId);
  const unit = useAppSelector((state) =>
    state.composition.units.find((u) => u.id === id)
  );
  const faction = useCurrentPlayerFaction()[0];

  if (!unit) {
    return <></>;
  }

  const modifications = UnitModifications[faction];

  return (
    <Box backgroundColor="primary" mr={2} p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text variant="h3">Modifications for "{unit.nickname}"</Text>
        <Button
          variant="filled"
          size="small"
          onClick={() => dispatch(removeUnitFromArmy(unit))}
        >
          Remove Unit
        </Button>
      </Box>
      <Box display="flex">
        {modifications.map((mod) => (
          <ModificationPreview key={mod.name} modification={mod} unit={unit} />
        ))}
      </Box>
    </Box>
  );
}

function ModificationPreview({
  modification,
  unit,
}: {
  modification: Modification;
  unit: Unit;
}) {
  const dispatch = useAppDispatch();
  const pointsRemaining = usePointsRemaining();
  const hasMod = unit.modifications.includes(modification);

  const mayAddMod = useMemo(() => {
    if (pointsRemaining < modification.cost) {
      return false;
    } else {
      return true;
    }
  }, [pointsRemaining, modification]);
  const disabled = !mayAddMod && !hasMod;

  const toggleModification = useCallback(() => {
    if (hasMod) {
      dispatch(removeModificationFromActiveUnit(modification));
    } else {
      dispatch(addModificationToActiveUnit(modification));
    }
  }, [dispatch, hasMod, modification]);

  const label = useMemo(() => {
    return `${modification.name} (${modification.cost}pts)`;
  }, [modification]);

  return (
    <Box mr={2} mt={2}>
      <Button
        variant="filled"
        size="small"
        color={hasMod ? "primary" : undefined}
        onClick={toggleModification}
        disabled={disabled}
      >
        {label}
      </Button>
    </Box>
  );
}
