import { Route } from "routes";
import { useNavigate } from "react-router-dom";

import { Screen } from "components/Screen";
import { campaignChapters, Chapter } from "campaign";
import { Button, Text } from "ui";

import "./styles.scss";

export function CampaignMenuScreen() {
  const navigate = useNavigate();

  return (
    <Screen
      route={Route.CAMPAIGN_MENU}
      id="campaignMenuScreen"
      background="nyc"
    >
      <Text variant="title">Campaign</Text>
      <div className="content">
        <div className="buttonContainer">
          <Button onClick={() => navigate(Route.MAIN_MENU)} variant="filled">
            ← Back
          </Button>
        </div>
        <div className="chapterDisplayContainer">
          {campaignChapters.map((chapter) => (
            <ChapterDisplay key={chapter.name} chapter={chapter} />
          ))}
        </div>
      </div>
    </Screen>
  );
}

function ChapterDisplay({ chapter }: { chapter: Chapter }) {
  const { index, name } = chapter;
  const label = `Chapter ${index}: (Coming Soon)`;

  return (
    <div className="chapterDisplay">
      <Text variant="h3">{label}</Text>
      <Text variant="h1">{name}</Text>
    </div>
  );
}
