import { Control, Light, Model, Scene, Shape } from "gamehook";

import { Screen } from "components";
import { Route } from "routes";

export function MultiplayerRound() {
  return (
    <Screen id="multiplayerRoundScreen" route={Route.MULTIPLAYER_ROUND}>
      <Scene camera={{ variant: "perspective", position: [9, 0, 0] }}>
        <Control variant="fly" />
        <Model path="/assets/3D/postwarCity/scene.gltf" scale={[1, 1, 1]} />
        <Light variant="point" position={[10, 1, 2]} />
        <Light variant="ambient" position={[1, 1, 1]} />
        <Shape variant="box" position={[0, 0, 0]} />
      </Scene>
    </Screen>
  );
}
