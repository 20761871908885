import { ReactNode, useMemo } from "react";

import "./styles.scss";

interface IContainer {
  children: ReactNode;

  backgroundColor?: "opaque-dark";
  display?: "flex";
  alignItems?: "flex-end";
  justifyContent?: "space-between";
  fixed?: boolean;
  flexDirection?: "column";

  p?: 1 | 2 | 3 | 4;
}

export function Container({
  children,

  backgroundColor,
  display,
  alignItems,
  justifyContent,
  fixed,
  flexDirection,
  p,
}: IContainer) {
  const className = useMemo(() => {
    const classes = ["uiContainer"];

    if (backgroundColor) {
      classes.push(`background-color-${backgroundColor}`);
    }
    if (fixed) {
      classes.push("fixed");
    }
    if (p) {
      classes.push(`p${p}`);
    }
    if (display === "flex") {
      classes.push("flex");
      if (alignItems) {
        classes.push(`align-items-${alignItems}`);
      }
      if (justifyContent) {
        classes.push(`justify-content-${justifyContent}`);
      }
      if (flexDirection) {
        classes.push(`flex-direction-${flexDirection}`);
      }
    }
    return classes.join(" ");
  }, [
    alignItems,
    backgroundColor,
    fixed,
    justifyContent,
    flexDirection,
    display,
    p,
  ]);

  return <div className={className}>{children}</div>;
}

export function PageCenter({ children }: IContainer) {
  return <div className="uiPageCenter">{children}</div>;
}
