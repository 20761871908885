import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GameBoardOption,
  GameOptions,
  NumberOfPlayersOption,
  PointTotalOption,
  TurnLimitOption,
} from "gameplay/types";
const initialState: GameOptions = {
  gameBoard: "Random",
  numberOfPlayers: 2,
  pointTotal: 2000,
  turnLimit: "No Limit",
};

export const gameOptionsSlice = createSlice({
  name: "gameOptions",
  initialState,
  reducers: {
    resetGameOptions: () => initialState,
    setGameBoardSelection(state, action: PayloadAction<GameBoardOption>) {
      state.gameBoard = action.payload;
    },
    setNumberOfPlayersSelection(
      state,
      action: PayloadAction<NumberOfPlayersOption>
    ) {
      state.numberOfPlayers = action.payload;
    },
    setPointTotalSelection(state, action: PayloadAction<PointTotalOption>) {
      state.pointTotal = action.payload;
    },
    setTurnLimitSelection(state, action: PayloadAction<TurnLimitOption>) {
      state.turnLimit = action.payload;
    },
  },
});

export const {
  resetGameOptions,
  setGameBoardSelection,
  setNumberOfPlayersSelection,
  setPointTotalSelection,
  setTurnLimitSelection,
} = gameOptionsSlice.actions;

export default gameOptionsSlice.reducer;
