import "./styles.scss";

interface ICoverImage {
  alt: string;
  src: string;
}

export function CoverImage({ alt, src }: ICoverImage) {
  const styles = {
    backgroundImage: `url(${src})`,
  };
  return <div className="uiCoverImage" style={styles} />;
}
