// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uiModal.closed {\n  display: none;\n}\n.uiModal.wrapper {\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  position: fixed;\n  box-sizing: border-box;\n  padding: 10vh 10vw;\n}\n.uiModal .container {\n  background-color: black;\n  padding: 32px;\n  height: 100%;\n  width: 100%;\n}\n.uiModal .container .header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/ui/modal/styles.scss","webpack://./src/global.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;AADJ;AAIE;EACE,YAAA;EACA,aAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,uBCdS;EDeT,aAAA;EACA,YAAA;EACA,WAAA;AAHJ;AAKI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAHN","sourcesContent":["@import \"global\";\n\n.uiModal {\n  &.closed {\n    display: none;\n  }\n\n  &.wrapper {\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    position: fixed;\n    box-sizing: border-box;\n    padding: 10vh 10vw;\n  }\n  \n  .container {\n    background-color: $color-dark;\n    padding: 32px;\n    height: 100%;\n    width: 100%;\n\n    .header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n    }\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');\n\n$container-width: 1280px;\n\n$text-color-light: white;\n$text-color-dark: black;\n$color-dark: black;\n$text-background-dark-opaque: rgba(0,0,0,0.5);\n$background-dark-opaque: rgba(0,0,0,0.8);\n\n$primary: rgba(227,111,37,1);\n$text-background-primary-opaque: rgba(227,111,37,0.2);\n$text-background-primary-opaque-active: rgba(227,111,37,0.5);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
