import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FactionModification, Modification } from "gameplay/modifications";

import { BaseUnits, BigGuyUnit, Unit } from "gameplay/units";

interface CompositionState {
  activeUnitId: string | undefined;
  factionModifications: FactionModification[];
  units: Unit[];
}
const initialState: CompositionState = {
  activeUnitId: crypto.randomUUID(),
  factionModifications: [],
  units: [],
};

export const compositionSlice = createSlice({
  name: "composition",
  initialState,
  reducers: {
    addUnitToArmy(state, action: PayloadAction<Unit>) {
      state.units.push(action.payload);
      state.activeUnitId = action.payload.id;
    },
    addLegToBigGuy(state) {
      if (!state.units[0]) {
        const bigGuyUnit: BigGuyUnit = {
          ...BaseUnits["Big Guy"],
          id: crypto.randomUUID(),
          numberOfLegs: 0,
          faction: "Big Guy",
        };
        state.units.push(bigGuyUnit);
      }
      const unit = state.units[0] as BigGuyUnit;
      unit.cost += BaseUnits["Big Guy"].cost;
      unit.numberOfLegs += 1;
    },
    clearUnits: () => initialState,
    removeUnitFromArmy(state, action: PayloadAction<Unit>) {
      state.units = state.units.filter((u) => u.id !== action.payload.id);
      if (state.activeUnitId === action.payload.id) {
        state.activeUnitId = undefined;
      }
    },
    setUnitToActive(state, action: PayloadAction<string | undefined>) {
      state.activeUnitId = action.payload;
    },

    // Modifications
    addModificationToActiveUnit(state, action: PayloadAction<Modification>) {
      const unit = state.units.find((u) => u.id === state.activeUnitId);
      if (unit) {
        const hasMod = unit.modifications.find(
          (mod) => mod.name === action.payload.name
        );
        if (!hasMod) {
          unit.modifications.push(action.payload);
        }
      }
    },
    removeModificationFromActiveUnit(
      state,
      action: PayloadAction<Modification>
    ) {
      const unit = state.units.find((u) => u.id === state.activeUnitId);
      if (unit) {
        unit.modifications = unit.modifications.filter(
          (m) => m.name !== action.payload.name
        );
      }
    },
  },
});

export const {
  addUnitToArmy,
  addLegToBigGuy,
  clearUnits,
  removeUnitFromArmy,
  setUnitToActive,
  // Modifications
  addModificationToActiveUnit,
  removeModificationFromActiveUnit,
} = compositionSlice.actions;
export default compositionSlice.reducer;
