import { useGameRound } from "gameplay/hooks";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Route } from "routes";
import { useAppSelector } from "store/hooks";
import { Box, Button } from "ui";

export function StartGameButton() {
  const navigate = useNavigate();
  const gameRound = useGameRound();
  const units = useAppSelector((state) => state.composition.units);

  const handleStart = useCallback(() => {
    navigate(`${Route.MULTIPLAYER_ROUND}${gameRound.id}`);
  }, [navigate, gameRound.id]);

  const disabled = useMemo(() => {
    const hasUnits = units.length > 0;
    return !hasUnits;
  }, [units]);

  return (
    <Box pt={4}>
      <Button
        variant="filled"
        onClick={handleStart}
        disabled={disabled}
        fullWidth
      >
        Start Game
      </Button>
    </Box>
  );
}
