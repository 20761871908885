import { SceneDetailsContext } from "gamehook/scene/context";
import { useContext, useEffect } from "react";
import { XYZ } from "./types";

export interface Positional {
  orientation?: XYZ;
  position?: XYZ;
  scale?: XYZ;
}

export function usePosition(
  obj: THREE.Object3D | undefined,
  {
    position = [0, 0, 0],
    orientation = [0, 0, 0],
    scale = [1, 1, 1],
  }: Positional,
  options?: {
    updateControls?: boolean;
  }
) {
  const sceneDetails = useContext(SceneDetailsContext);
  const [posX, posY, posZ] = position;

  // Position
  useEffect(() => {
    obj?.position.set(posX, posY, posZ);
  }, [posX, posY, posZ, obj?.position, options?.updateControls, sceneDetails]);

  // Orientation
  useEffect(() => {
    obj?.rotation.set(...orientation);
  }, [obj?.rotation, orientation, options?.updateControls, sceneDetails]);

  // Scale
  useEffect(() => {
    obj?.scale.set(...scale);
  }, [obj?.scale, scale, options?.updateControls, sceneDetails]);
}
