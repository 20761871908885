import * as THREE from "three";

import { useThreeScene } from "gamehook/scene/context";
import { ReactNode, useEffect, useMemo } from "react";
import { Positional, usePosition } from "gamehook/physics/position";
import { Nameable, useName } from "gamehook/taxonomy";
import { Movable, useMovement } from "gamehook/physics/movement";
import { HierarchyContext, useParent } from "gamehook/hierarchy";

type ShapeVariant = "box" | "sphere";
interface BaseShape extends Movable, Nameable, Positional {
  children?: ReactNode;
  variant: ShapeVariant;
}

interface BoxShape extends BaseShape {
  variant: "box";
}
interface SphereShape extends BaseShape {
  variant: "sphere";
}
type IShape = BoxShape | SphereShape;

export function Shape({ children, ...props }: IShape) {
  const scene = useThreeScene();

  const geometry = useMemo(() => {
    return new THREE.BoxGeometry(1, 1, 1);
  }, []);
  const material = useMemo(() => {
    return new THREE.MeshNormalMaterial();
  }, []);
  const mesh = useMemo(() => {
    return new THREE.Mesh(geometry, material);
  }, [geometry, material]);

  useName(mesh, props);
  usePosition(mesh, props);
  useMovement(mesh, props);

  const parent = useParent();

  useEffect(() => {
    if (parent) {
      parent.add(mesh);
    } else {
      scene.add(mesh);
    }
    return () => {
      mesh.removeFromParent();
      scene.remove(mesh);
      geometry.dispose();
      material.dispose();
    };
  }, [geometry, scene, mesh, material, parent]);

  return (
    <HierarchyContext.Provider value={{ parent: mesh }}>
      {children}
    </HierarchyContext.Provider>
  );
}
