import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchGameRound } from "api";
import { Screen } from "components";
import { Route } from "routes";
import { setGameRound, setGameRoundStatus } from "store/gameRound";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Box, Container, PageCenter, Text } from "ui";

import { GameDetails } from "./GameDetails";
import { PlayerList } from "./PlayerList";
import { StartButton } from "./StartButton";

function useGameStatusListener() {
  const dispatch = useAppDispatch();
  const gameRound = useAppSelector((state) => state.gameRound);
  const gameOptions = useAppSelector((state) => state.gameOptions);

  const playersInLobbyCount = gameRound.players.length;
  const emptySlots = gameOptions.numberOfPlayers - playersInLobbyCount;
  const playersWithoutFactionSelection = gameRound.players.find(
    (p) => p.factions.length === 0
  );

  useEffect(() => {
    if (playersInLobbyCount === 0) {
      dispatch(setGameRoundStatus("Not Instantiated"));
    } else if (emptySlots > 0) {
      dispatch(setGameRoundStatus("Awaiting Players"));
    } else if (playersWithoutFactionSelection) {
      dispatch(setGameRoundStatus("Awaiting Faction Selection"));
    } else {
      dispatch(setGameRoundStatus("Ready to Start"));
    }
  }, [
    dispatch,
    playersInLobbyCount,
    emptySlots,
    playersWithoutFactionSelection,
  ]);
}

export function MultiplayerLobby() {
  const { gameId } = useParams();
  const dispatch = useDispatch();

  // On page load, add players to game
  useEffect(() => {
    if (gameId) {
      fetchGameRound(gameId).then((gameRound) => {
        dispatch(setGameRound(gameRound));
      });
    }
  }, [gameId, dispatch]);

  useGameStatusListener();

  return (
    <Screen
      route={Route.MULTIPLAYER_LOBBY}
      id="multiplayerLobby"
      background="nyc"
    >
      <PageCenter>
        <Container backgroundColor="opaque-dark" fixed p={4}>
          <Box display="flex" justifyContent="space-between">
            <Text variant="h1">Multiplayer Lobby</Text>
            <GameDetails />
          </Box>
          <PlayerList />
          <StartButton />
        </Container>
      </PageCenter>
    </Screen>
  );
}
