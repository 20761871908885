import { useAppSelector } from "store/hooks";
import { Box, Text } from "ui";
import { useCurrentPlayerFaction, useCurrentArmyValue } from "gameplay/hooks";

export function PointHeader() {
  const factions = useCurrentPlayerFaction();
  const pointTotal = useAppSelector((state) => state.gameOptions.pointTotal);
  const currentArmyValue = useCurrentArmyValue();

  const factionLabel = factions.join(", ");

  const pointLabel = `${currentArmyValue}/${pointTotal} points used`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pb={4}
      alignItems="center"
      fullWidth
    >
      <Text variant="h1">{factionLabel}</Text>
      <Text variant="h3">{pointLabel}</Text>
    </Box>
  );
}
