import * as THREE from "three";
import { createContext, useContext } from "react";

export interface SceneDetails {
  id: string;

  canvas: HTMLCanvasElement;
  render: () => void;
  camera: THREE.Camera;

  threeScene: THREE.Scene;
}
export const SceneDetailsContext = createContext<SceneDetails>({
  id: crypto.randomUUID(),

  canvas: null as unknown as HTMLCanvasElement, // Will be set by context provider
  camera: new THREE.Camera(),

  render: () => {},
  threeScene: new THREE.Scene(),
});

export function useThreeScene() {
  return useContext(SceneDetailsContext).threeScene;
}
