import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { Route } from "routes";
import { Screen } from "components/Screen";
import { Button, Container, PageCenter, Text } from "ui";

export function WelcomeScreen() {
  const navigate = useNavigate();
  const [displayTime, setDisplayTime] = useState(new Date().toLocaleString());

  useEffect(() => {
    setInterval(() => {
      const date = new Date();

      date.setFullYear(date.getFullYear() + 6);
      setDisplayTime(date.toLocaleString());
    }, 1000);
  }, []);

  return (
    <Screen route={Route.WELCOME} id="welcomeScreen" background="nyc">
      <PageCenter>
        <Container display="flex" alignItems="flex-end" flexDirection="column">
          <Text variant="title">CASSANDRA</Text>
          <Button variant="filled" onClick={() => navigate(Route.MAIN_MENU)}>
            Begin →
          </Button>
          <Text variant="subtitle">{displayTime}</Text>
        </Container>
      </PageCenter>
    </Screen>
  );
}
