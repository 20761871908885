export interface Chapter {
  name: string;
  path: string;
  index: number; // Could be inferred from the list, but useful for making titles, eg "Chapter 1: Drawbridge"
  locked: boolean;
}

export const campaignChapters: Chapter[] = [
  {
    name: "Drawbridge",
    path: "/",
    index: 1,
    locked: true,
  },
  {
    name: "An Unbelievable Storm's Coming",
    path: "/",
    index: 2,
    locked: true,
  },
  {
    name: "Embassies of Survival",
    path: "/",
    index: 3,
    locked: true,
  },
  {
    name: "The Knowledge Market",
    path: "/",
    index: 4,
    locked: true,
  },
  {
    name: "Ten Minutes of your Time",
    path: "/",
    index: 5,
    locked: true,
  },
  {
    name: "The Dawn Callers",
    path: "/",
    index: 6,
    locked: true,
  },
  {
    name: "Two Lunatics",
    path: "/",
    index: 7,
    locked: true,
  },
  {
    name: "The Life of Henry",
    path: "/",
    index: 8,
    locked: true,
  },
  {
    name: "Five, Give or Take",
    path: "/",
    index: 9,
    locked: true,
  },
  {
    name: "It's Not Easy Beating Green",
    path: "/",
    index: 10,
    locked: true,
  },
  {
    name: "Wise Guys",
    path: "/",
    index: 11,
    locked: true,
  },
  {
    name: "The Cosmic Casket",
    path: "/",
    index: 12,
    locked: true,
  },
  {
    name: "Face of a Frightened God",
    path: "/",
    index: 13,
    locked: true,
  },
  {
    name: "Later",
    path: "/",
    index: 14,
    locked: true,
  },
];
