import { FactionNames, GamePlayer } from "gameplay/types";
import _ from "lodash";
import { addPlayerToGame, setFactionSelectionForPlayer } from "store/gameRound";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Box, Button, Dropdown, Text } from "ui";

export function PlayerList() {
  const dispatch = useAppDispatch();
  const gameRound = useAppSelector((state) => state.gameRound);
  const gameOptions = useAppSelector((state) => state.gameOptions);
  const emptySlots = gameOptions.numberOfPlayers - gameRound.players.length;

  const handleAddComputer = () => {
    const computerPlayerCount = gameRound.players.filter(
      (p) => p.entrant.isComputer
    ).length;
    const newPlayer: GamePlayer = {
      entrant: {
        difficulty: "Easy",
        isComputer: true,
        id: `computer${computerPlayerCount}`,
        isHost: false,
        isLocal: false,
        username: `Computer ${computerPlayerCount + 1}`,
      },
      factions: ["Tolja"],
    };
    dispatch(addPlayerToGame(newPlayer));
  };

  return (
    <Box>
      {gameRound.players.map((player) => {
        let label = player.entrant.username;
        if (player.entrant.isHost) {
          label += " (Host)";
        }

        return (
          <Box
            key={player.entrant.id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant="h3">{label}</Text>
            <Dropdown
              options={Array.from(FactionNames)}
              onChange={(value) =>
                dispatch(
                  setFactionSelectionForPlayer({
                    entrantId: player.entrant.id,
                    factions: [value],
                  })
                )
              }
            />
          </Box>
        );
      })}

      {_.range(0, emptySlots).map((i) => {
        return (
          <Box
            key={i}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={2}
          >
            <Text variant="h3">Awaiting Player...</Text>
            <Button variant="filled" onClick={handleAddComputer}>
              Add Computer
            </Button>
          </Box>
        );
      })}
    </Box>
  );
}
