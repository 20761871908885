export type ScreenName = "HOME" | "START" | "CAMPAIGN_HOME" | "SETTINGS";

export enum Route {
  WELCOME = "/",
  MAIN_MENU = "/start/",
  SETTINGS = "/settings",

  // Single-Player Campaign
  CAMPAIGN_MENU = "/campaign/",
  CHAPTER_ONE = "/campaign/drawbridge/",
  CHAPTER_TWO = "/campaign/an-unbelievable-storms-coming/",
  CHAPTER_THREE = "/campaign/embassies-of-survival/",

  // Multiplayer
  MULTIPLAYER_MENU = "/multiplayer/",
  MULTIPLAYER_LOBBY = "/multiplayer/lobby/",
  MULTIPLAYER_COMPOSE = "/multiplayer/compose/",
  MULTIPLAYER_ROUND = "/multiplayer/round/",

  // Challenge Mode
  CHALLENGE = "/challenge",
}
