import { useCallback, useMemo } from "react";

import { Unit } from "gameplay/units";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Box, Button, Text } from "ui";
import { setUnitToActive } from "store/composition";
import { getTotalUnitCost } from "gameplay/cost";

export function UnitList() {
  const units = useAppSelector((state) => state.composition.units);
  return (
    <Box py={2} className="unitList">
      <Text variant="h3">Active Units:</Text>
      {units.map((unit) => (
        <UnitPreview unit={unit} key={unit.id} />
      ))}
    </Box>
  );
}

function UnitPreview({ unit }: { unit: Unit }) {
  const dispatch = useAppDispatch();

  const label = useMemo(() => {
    const cost = getTotalUnitCost(unit);
    return `${unit.nickname}: ${cost}pts`;
  }, [unit]);

  const isActive = useAppSelector(
    (state) => state.composition.activeUnitId === unit.id
  );

  const toggleActive = useCallback(() => {
    if (isActive) {
      dispatch(setUnitToActive(undefined));
    } else {
      dispatch(setUnitToActive(unit.id));
    }
  }, [dispatch, isActive, unit]);

  return (
    <Box py={2}>
      <Button
        variant="filled"
        size="small"
        onClick={toggleActive}
        fullWidth
        color={isActive ? "primary" : undefined}
      >
        {label}
      </Button>
    </Box>
  );
}
