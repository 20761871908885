import { ReactEventHandler } from "react";
import { Text } from "ui";

import "./styles.scss";

type DropdownValue = string | number;
interface IDropdown<T extends DropdownValue> {
  label?: string;
  options: T[];
  onChange: (value: T) => void;
  value?: T;
}

export function Dropdown<T extends DropdownValue>({
  label,
  options,
  onChange,
  value,
}: IDropdown<T>) {
  const handleSelection: ReactEventHandler<HTMLSelectElement> = (event) => {
    onChange(event.currentTarget.value as T);
  };
  return (
    <div className="uiDropdown">
      {label && <Text variant="h2">{label}</Text>}
      <select onChange={handleSelection} value={value}>
        {options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}
