import * as THREE from "three";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { SceneDetails, SceneDetailsContext, useThreeScene } from "./context";
import { CameraProps, useCreateCamera } from "gamehook/camera";
import { AnimationLoop } from "gamehook/animation/providers";

interface IScene {
  antialias?: boolean;
  camera?: CameraProps;
  children: ReactNode;
  id?: string;
}
interface OuterScene extends IScene {
  canvas?: HTMLCanvasElement;
}
interface InnerScene extends IScene {
  canvas: HTMLCanvasElement;
  id: string;
}

export function GameScene(props: InnerScene) {
  const { antialias, children, id } = props;

  const canvas = props.canvas;
  const camera = useCreateCamera(props.camera);
  const threeScene = useThreeScene();

  const renderer = useMemo(() => {
    return new THREE.WebGLRenderer({
      antialias,
      canvas,
    });
  }, [antialias, canvas]);

  const { innerWidth, innerHeight } = window;

  useEffect(() => {
    renderer.setSize(innerWidth, innerHeight);
  }, [innerHeight, innerWidth, renderer]);

  const render = useCallback(() => {
    if (renderer) {
      renderer.render(threeScene, camera);
    }
  }, [renderer, camera, threeScene]);

  const contextValue: SceneDetails = useMemo(() => {
    return {
      canvas,
      id: id,
      render,
      camera,
      threeScene,
    };
  }, [id, canvas, render, camera, threeScene]);

  return (
    <SceneDetailsContext.Provider value={contextValue}>
      <AnimationLoop />
      {children}
    </SceneDetailsContext.Provider>
  );
}

export function Scene(props: OuterScene) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [ready, setReady] = useState(false);
  const id = useMemo(() => {
    return props.id ?? crypto.randomUUID();
  }, [props.id]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 100);
  }, []);

  return (
    <>
      <canvas ref={canvasRef} id={id} />
      {ready && canvasRef.current && (
        <GameScene {...props} id={id} canvas={canvasRef.current} />
      )}
    </>
  );
}
