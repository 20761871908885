// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uiDropdown {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 8px 0px;\n}\n.uiDropdown select {\n  background-color: rgba(0, 0, 0, 0.8);\n  color: white;\n  border: 0;\n  padding: 16px;\n  font-size: 20px;\n}", "",{"version":3,"sources":["webpack://./src/ui/dropdown/styles.scss","webpack://./src/global.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;AAAJ;AAEI;EACE,oCCDmB;EDEnB,YCNa;EDOb,SAAA;EACA,aAAA;EACA,eAAA;AAAN","sourcesContent":["@import \"global\";\n\n.uiDropdown {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 8px 0px;\n\n    select {\n      background-color: $background-dark-opaque;\n      color: $text-color-light;\n      border: 0;\n      padding: 16px;\n      font-size: 20px;\n    }\n\n}\n","@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');\n\n$container-width: 1280px;\n\n$text-color-light: white;\n$text-color-dark: black;\n$color-dark: black;\n$text-background-dark-opaque: rgba(0,0,0,0.5);\n$background-dark-opaque: rgba(0,0,0,0.8);\n\n$primary: rgba(227,111,37,1);\n$text-background-primary-opaque: rgba(227,111,37,0.2);\n$text-background-primary-opaque-active: rgba(227,111,37,0.5);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
