import * as THREE from "three";
import { ReactNode, useEffect, useMemo } from "react";
import { Positional, usePosition } from "gamehook/physics/position";

export interface CameraProps extends Positional {
  children?: ReactNode;
  variant: "orthographic" | "perspective";

  left?: number;
  right?: number;
  top?: number;
  bottom?: number;

  near?: number;
  far?: number;

  fov?: number;
  aspect?: number;
}

const DefaultCameraProps: CameraProps = {
  variant: "perspective",
  fov: 75,
  near: 0.1,
  far: 1000,
};
export function useCreateCamera(
  userProps: CameraProps | undefined
): THREE.Camera {
  const props = userProps ?? DefaultCameraProps;
  const { left, right, top, bottom, near, far, fov, variant } = props;
  const { innerWidth, innerHeight } = window;
  const aspect = props?.aspect ?? innerWidth / innerHeight;

  const camera = useMemo(() => {
    if (variant === "perspective") {
      return new THREE.PerspectiveCamera(fov, aspect, near, far);
    } else if (variant === "orthographic") {
      return new THREE.OrthographicCamera(left, right, top, bottom, near, far);
    } else {
      throw new Error("Camera not provided");
    }
  }, [left, right, top, bottom, near, far, fov, aspect, variant]);

  usePosition(camera, props);

  return camera;
}
