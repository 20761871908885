// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#multiplayerMenuScreen .gameSelectonContainer {\n  width: 100%;\n  box-sizing: border-box;\n  margin: 20px;\n  box-sizing: border-box;\n  height: 60vh;\n}\n#multiplayerMenuScreen .gameMenu {\n  width: 100%;\n  background-color: rgba(0, 0, 0, 0.8);\n  padding: 16px;\n  box-sizing: border-box;\n  margin: 16px 0px;\n}\n#multiplayerMenuScreen .uiButton.selected {\n  background-color: rgba(227, 111, 37, 0.2);\n}\n#multiplayerMenuScreen .createGameButton .buttonContainer {\n  display: flex;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/screens/multiplayer/menu/styles.scss","webpack://./src/global.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,sBAAA;EACA,YAAA;EACA,sBAAA;EACA,YAAA;AADJ;AAIE;EACE,WAAA;EACA,oCCLqB;EDMrB,aAAA;EACA,sBAAA;EACA,gBAAA;AAFJ;AAKE;EACE,yCCT6B;ADMjC;AAOI;EACE,aAAA;EACA,WAAA;AALN","sourcesContent":["@import 'global';\n\n#multiplayerMenuScreen {\n  .gameSelectonContainer {\n    width: 100%;\n    box-sizing: border-box;\n    margin: 20px;\n    box-sizing: border-box;\n    height: 60vh;\n  }\n\n  .gameMenu {\n    width: 100%;\n    background-color: $background-dark-opaque;\n    padding: 16px;\n    box-sizing: border-box;\n    margin: 16px 0px;\n  }\n\n  .uiButton.selected {\n    background-color: $text-background-primary-opaque;\n  }\n\n  .createGameButton {\n    .buttonContainer {\n      display: flex;\n      width: 100%;\n    }\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');\n\n$container-width: 1280px;\n\n$text-color-light: white;\n$text-color-dark: black;\n$color-dark: black;\n$text-background-dark-opaque: rgba(0,0,0,0.5);\n$background-dark-opaque: rgba(0,0,0,0.8);\n\n$primary: rgba(227,111,37,1);\n$text-background-primary-opaque: rgba(227,111,37,0.2);\n$text-background-primary-opaque-active: rgba(227,111,37,0.5);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
